class AnchorDropdown {

	static name() {
		return 'AnchorDropdown';
	}

	constructor(props, el, $, Utils) {

		const objects = {
			$el: $(el),
		};

		this.initAnchorDropdown = () => {
			objects.$el.change(function(){
				window.location.href = '#'+$(this).val();
			});
		};
	}
	init() {
		this.initAnchorDropdown();
	}
}

export default AnchorDropdown;
