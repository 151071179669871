import 'regenerator-runtime/runtime';
//import * as pdfjs from 'pdfjs-dist';

class PDFViewer {

	static name() {
		return 'pdfViewer';
	}

	constructor(props, el, $) {
		const objects = {
			$el: $(el),
			$pdfContainer: $(el).find('[data-pdf-container]'),
			$canvas: $(el).find('[data-canvas]'),
			$pdfFile: $(el).data('pdf-file'),
			$pageDisplay: $(el).find('[data-page-display]'),
			$prevPageButton: $(el).find('[data-prev-page-button]'),
			$nextPageButton: $(el).find('[data-next-page-button]'),
			$zoomInButton: $(el).find('[data-zoom-in-button]'),
			$zoomOutButton: $(el).find('[data-zoom-out-button]')

		};
		const ctx = objects.$canvas.get(0).getContext('2d');
		let pdfDoc = null;
		let currentPage = 1;
		let zoom = 1;
		
		let pdfjs = null;
		
		const loadPdfJs = () => {
			return new Promise((resolve, reject) => {
				// Manually load the chunk from your desired path
				const chunkName = 'pdf-js-vendor';
				const chunkUrl = `/modules/vicor-assets/javascript/${chunkName}.js`;

				// Create script tag to load the chunk
				const script = document.createElement('script');
				script.src = chunkUrl;
				script.onload = () => {
					try {
						// Once loaded, require the module
						const pdfjsLib = require('pdfjs-dist');
						pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.worker.min.js';
						resolve(pdfjsLib);
					} catch (error) {
						reject(error);
					}
				};
				script.onerror = reject;

				document.body.appendChild(script);
			});
		};
		
		// Load the PDF document
		this.loadPDF = async (url) => {
			
			if (!pdfjs) {
				pdfjs = await loadPdfJs();
			}
	
			try {
				var host = window.location.protocol + '//' + window.location.host;
				pdfDoc = await pdfjs.getDocument(host+url).promise;
				console.log(`PDF loaded. Total pages: ${pdfDoc.numPages}`);
				this.renderPage(currentPage);
			} catch (error) {
				console.error('Error loading PDF:', error);
			}
		};
		
		// Render a specific page
		this.renderPage = async (pageNumber) => {
			try {
				const page = await pdfDoc.getPage(pageNumber);
	
				// Set up the viewport with the current zoom level
				const viewport = page.getViewport({ scale: zoom });
	
				var outputScale = window.devicePixelRatio || 1;
				objects.$canvas.get(0).width = Math.floor(viewport.width * outputScale);
				objects.$canvas.get(0).height = Math.floor(viewport.height * outputScale);
				objects.$canvas.get(0).style.width = Math.floor(viewport.width) + "px";
				objects.$canvas.get(0).style.height =  Math.floor(viewport.height) + "px";

				var transform = outputScale !== 1
					? [outputScale, 0, 0, outputScale, 0, 0]
					: null;

				// Adjust canvas size to match the viewport dimensions
				objects.$canvas.height = viewport.height;
				objects.$canvas.width = viewport.width;
	
				// Render the page into the canvas
				const renderContext = {
					canvasContext: ctx,
					transform: transform,
					viewport: viewport
				};
				await page.render(renderContext).promise;
	
				// Update the UI to reflect the current page
				this.updatePageNumber(pageNumber);
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		};
		
		this.nextPage = () => {
			if (currentPage < pdfDoc.numPages) {
				currentPage++;
				this.renderPage(currentPage);
			} else {
				console.log('You are on the last page.');
			}
		};
		
		this.previousPage = () => {
			if (currentPage > 1) {
				currentPage--;
				this.renderPage(currentPage);
			} else {
				console.log('You are on the first page.');
			}			
		};
		
		this.zoomIn = () => {
			zoom += 0.25;
			this.renderPage(currentPage);
		};

		this.zoomOut = () => {
			if (zoom > 0.25) {
				zoom -= 0.25;
				this.renderPage(currentPage);
			}
		};

		// Update the page number display
		this.updatePageNumber = (pageNumber) => {
			const pageDisplay = objects.$pageDisplay;
			if (pageDisplay) {
				pageDisplay.textContent = `Page ${pageNumber} of ${pdfDoc.numPages}`;
			}
		};
		
		this.initPDFViewer = () => {

			this.loadPDF(objects.$pdfFile);
			
			objects.$prevPageButton.on('click', this.previousPage);
			objects.$nextPageButton.on('click', this.nextPage);
			objects.$zoomInButton.on('click', this.zoomIn);
			objects.$zoomOutButton.on('click', this.zoomOut);
		};

	}

	init() {
		this.initPDFViewer();
	}

}

export default PDFViewer;
